import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser`

/**
 * CREATE
 * Cria um novo anunciante
 */
export const createAdvertiserService = (body: CreateAdvertiserPayload) =>
  axios.post<CreateAdvertiserResponse>(`${baseURL}`, body)

/**
 * Upload
 * Faz upload de uma imagem para account info
 */

export const uploadAdvertiserService = ({ body, axiosOptions }) =>
  axios.post<CreateAdvertiserResponse>(`${baseURL}/upload`, body, axiosOptions)

/**
 * VIEW
 * Retorna dados do publisher com opção de retornar dados da conta
 */
export const getAdvertiserService = ({
  url: { id },
  params
}: GetAdvertiserResquest) =>
  axios.get<GetAdvertiserResponse>(`${baseURL}/${id}`, { params })
