import { bffRetailMedia } from '~/middlewares/axios'

const baseURL = '/audience'

/**
 * Get one
 */
export const getAudienceService = (audienceId: AudienceId) =>
  bffRetailMedia.get<AudienceData>(`${baseURL}/${audienceId}`)

/**
 * List all
 */
export const listAudiencesService = (params?: ListAudienceQueryParams) =>
  bffRetailMedia.get<ListAudienceResponse>(baseURL, { params })

/**
 * Create
 */
export const createAudienceService = (body: AudienceCreateRequestBody) =>
  bffRetailMedia.post<AudienceCreateResponse>(baseURL, body)
