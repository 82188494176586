import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

export const listCategoriesServiceKey = 'get-campaign-category'
export const listCategoriesService = (
  params: RequestGetCategoriesServiceParams
) => {
  const { campaignId, count, page, quantity } = params
  return axios.get<GetCategoriesServiceResponse>(
    `${baseURL}/${campaignId}/category`,
    { params: { count, quantity, page } }
  )
}

export const changeStatusCategoryService = (params: {
  categoryId: string
  campaignId: string
  active: boolean
}) =>
  axios.patch<CampaignCategory>(`${baseURL}/category/${params.categoryId}`, {
    active: params.active,
    campaign_id: params.campaignId
  })

export const addCategoriesBulkService = (
  params: RequestAddCategoryBulkParams
) =>
  axios.post<CampaignCategory[]>(
    `${baseURL}/${params.campaignId}/category/bulk`,
    { category_ids: params.categoryIds }
  )
