import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser-publisher`

/**
 * List publisher
 */

export const listPublishersServiceKey = 'LIST_PUBLISHERS'

// With pagination
export const listPublishersService = (
  params: ListAdvertiserPublisherServiceRequestParams = null
) =>
  axios.get<ListPublishersServiceResponse>(`${baseURL}/publishers`, { params })

// Without pagination
export const getPublishersService = (
  params: ListAdvertiserPublisherServiceRequestParams = null
) =>
  axios.get<PublishersServiceData>(`${baseURL}/publishers`, {
    params: { count: false, ...params }
  })

/**
 * List advertiser
 */

export const listAdvertisersServiceKey = 'LIST_ADVERTISERS'

export const listAdvertisersService = (
  params: ListAdvertiserPublisherServiceRequestParams = null
) =>
  axios.get<ListAdvertisersServiceResponse>(`${baseURL}/advertisers`, {
    params
  })

/**
 * Edit link
 */
export const patchLinkPublisherAdvertiserService = ({
  advertiserId,
  body
}: AdvertiserPublisherLinkPatchServiceParams) =>
  axios.patch<AdvertiserPublisherLinkPatchLinkResponse>(
    `${baseURL}/${advertiserId}`,
    body
  )

/**
 * Exports
 */
export default {
  listPublishers: listPublishersService,
  listAdvertisers: listAdvertisersService,
  listAdvertisersService
}
